import './index.scss'

$('footer .footer-nav ul li .title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('active')){
    $parent.removeClass('active').find('.sub-nav').slideUp()
  }else{
    $parent.siblings().removeClass('active').find('.sub-nav').slideUp()
    $parent.addClass('active').find('.sub-nav').slideDown()
  }
})
$(document).ready(function() {  
  // 检查是否已经存在缓存  
  if(localStorage.getItem('cookieConsent')){
    if (localStorage.getItem('cookieConsent') === 'rejected') {  
      $('.cookie-consent').removeClass('hide').addClass('show');  
    } else {  
      $('.cookie-consent').addClass('hide').removeClass('show');  
    }  
  }else{
    $('.cookie-consent').removeClass('hide').addClass('show');
  }
  

  // 设置点击事件  
  $('.cookie-consent .reject').on('click', function() {  
    localStorage.setItem('cookieConsent', 'rejected'); // 记录拒绝信息  
    $('.cookie-consent').removeClass('show').addClass('hide');  
  });  

  $('.cookie-consent .accept').on('click', function() {  
    localStorage.setItem('cookieConsent', 'accepted'); // 记录接受信息  
    $('.cookie-consent').removeClass('show').addClass('hide');  
  });  
});