import './index.scss'
// import '../../assets/scripts/lenis'

// const lenis = new Lenis({
//   duration: 1.2,
//   easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
//   direction: "vertical",
//   gestureDirection: "vertical",
//   smooth: true,
//   smoothTouch: false,
//   touchMultiplier: 2,
// });

// function raf(time) {
//   lenis.raf(time);
//   requestAnimationFrame(raf);
// }

// requestAnimationFrame(raf);
// lenis.stop()

// $(document).ready(function(){
//   lenis.start()
// })

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
    // lenis.start()
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  }else{
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('header .menu-mask').fadeIn(200)
    // lenis.stop()
    $('body').css({
      'height':'100vh',
      'overflow-y':'hidden'
    })
  }
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  },200)
})

$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$('#header-search-open').on('click',function(){
  $('.header-search').addClass('active')
  $('#header-search-input').focus()
})
$('.header-search .header-search-close').on('click',function(){
  $('.header-search').removeClass('active')
  $('#header-search-input').blur()
})

var menuover_tid = [];
var menuout_tid = [];
$(document).ready(function(){
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })

  $(".header .nav ul li").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(menuout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        menuover_tid[ index ] = setTimeout(function () {
          $(_self).addClass('show-sub')
          let H = $('.header').outerHeight() + $(_self).find('.sub-nav').outerHeight()
          $('.header .header-bg').css('height', H + 'px')
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(menuover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        menuout_tid[ index ] = setTimeout(function () {
          $(_self).removeClass('show-sub')
          $('.header .header-bg').css('height', '100%')
        }, 201);
      });
    });
})

let beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if(scroH > 150){
    $('.header,.header-search').addClass('show-bg')
  }else{
    $('.header,.header-search').removeClass('show-bg')
  }

  //判断页面是上滚还是下滚
  let delta = scroH - beforeScroH;
  if (delta > 0 && scroH > 0) {
    if(scroH > 50){
      $('.header').addClass('trans')
    }else{
      $('.header').removeClass('trans')
    }
  }
  if (delta < 0) {
    $('.header').removeClass('trans')
  }

  beforeScroH = scroH;
});