
import {WOW} from '../../../node_modules/wowjs/dist/wow';
new WOW({
  offset: 100,
  mobile: false
}).init();


$('.mob-open-btn .search-btn').on('click',function(){
  $('.common-search-form .fixed-cont').addClass('show')
})
$('.common-search-form .mob-close-btn').on('click',function(){
  $('.common-search-form .fixed-cont').removeClass('show')
})

$('.common-search-form .form-select .select-title').on('click',function(){
  let parent = $(this).closest('.form-select')
  parent.addClass('active').siblings('.form-select').removeClass('active')
})

$('.common-search-form .form-select ul li').on('click',function(){
  let parent = $(this).closest('.form-select')
  let value = $(this).html()
  parent.removeClass('active').find('.select-title .text p').html(value)
})

$('body').on('click',function(e){
  if($(e.target).parents('.form-select').length == 0){
    if($('.form-select').length > 0){
      $('.form-select').removeClass('active')
    }
  }
})